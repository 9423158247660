import {
  DashboardOutlined,
  HomeOutlined,
  RetweetOutlined,
  CreditCardOutlined,
  FileTextOutlined,
  PlusSquareOutlined,
  DownloadOutlined,
  ReloadOutlined,
  UploadOutlined,
  DatabaseOutlined,
  CoffeeOutlined,
  GiftOutlined,
} from "@ant-design/icons";
import { ADMIN_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "master-voucher",
    path: `${ADMIN_PREFIX_PATH}/master-voucher`,
    title: "Data Voucher",
    icon: GiftOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "master-lokasi",
    path: `${ADMIN_PREFIX_PATH}/master-lokasi`,
    title: "Data Lokasi Kafe",
    icon: DatabaseOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "master-delivery",
    path: `${ADMIN_PREFIX_PATH}/master-delivery`,
    title: "Data Titik Pengantaran",
    icon: DatabaseOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "master-delivery-fee",
    path: `${ADMIN_PREFIX_PATH}/master-delivery-fee`,
    title: "Data Biaya Pengantaran",
    icon: DatabaseOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "master-kasir",
    path: `${ADMIN_PREFIX_PATH}/master-kasir`,
    title: "Data Kasir",
    icon: DatabaseOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "data-vendor",
    path: `${ADMIN_PREFIX_PATH}/cafe/data-vendor`,
    title: "Data Vendor",
    icon: CoffeeOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "rekap-transaksi",
    path: `${ADMIN_PREFIX_PATH}/cafe/rekap-transaksi`,
    title: "Rekap Transaksi",
    icon: RetweetOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "pencairan-dana",
    path: `${ADMIN_PREFIX_PATH}/cafe/pencairan-dana`,
    title: "Pencairan Dana",
    icon: CreditCardOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
