import {
  DashboardOutlined,
  HomeOutlined,
  RetweetOutlined,
  CreditCardOutlined,
  FileTextOutlined,
  PlusSquareOutlined,
  DownloadOutlined,
  ReloadOutlined,
  UploadOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import { ADMIN_PREFIX_PATH } from "configs/AppConfig";



const dashBoardNavTree = [
  {
    key: "angsuran",
    path: `${ADMIN_PREFIX_PATH}/rekap-transaksi`,
    title: "Rekap Transaksi",
    icon: RetweetOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "riwayat-bulanan",
    path: `${ADMIN_PREFIX_PATH}/bukti-transfer`,
    title: "Upload Bukti Transfer",
    icon: UploadOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "download-csv",
    path: `${ADMIN_PREFIX_PATH}/download-csv`,
    title: "Download Rekap CSV",
    icon: DownloadOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "riwayat-pembayaran",
    path: `${ADMIN_PREFIX_PATH}/riwayat-pembayaran`,
    title: "Riwayat Pembayaran",
    icon: ReloadOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const name = localStorage.getItem('name')

if(name){
  if(name.search('Elkopra - HQ') > 0){

    dashBoardNavTree.push(
      {
        key: "settlement",
        path: `${ADMIN_PREFIX_PATH}/settlement`,
        title: "Pelunasan Pinjaman",
        icon: DatabaseOutlined,
        breadcrumb: false,
        submenu: [],
      }
    )
  }
}

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
